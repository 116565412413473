@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

html {
  background-color: #1a1a1a;
  scroll-behavior: smooth;
  scroll-padding-top: 70px;
}
